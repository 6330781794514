import React from 'react'
import styles from './aspect-ratio.module.scss';

export function AspectRatio(props) {
  return (
    <div className={styles.aspectRatio} style={{ paddingBottom: `${(1 / props.ratio) * 100}%` }}>
      {props.children}
    </div>
  );
}
