import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "components/seo"
import Layout from "components/layout/layout"
import Blog from 'components/blog/blog';
import { Container } from 'components/layout/container'

class BlogPage extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout title="Blogs">
        <SEO title="Blog" />
        <Container>
          <div>
            <Link to="/blog/th" className="link link-no-underline">ดูบทความภาษาไทย (TH)</Link>
          </div>
        </Container>
        <Blog posts={posts} />
      </Layout>
    )
  }
}

export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___date],
        order: DESC
      },
      filter: {
        frontmatter: {
          language: { eq: "en" }
        }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            language
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
