import React from 'react'
import { Link } from 'gatsby'
import { Container } from 'components/layout/container'
import styles from './blog.module.scss'
import GatsbyImage from 'gatsby-image'
import { AspectRatio } from 'components/utils/aspect-ratio/aspect-ratio'

const Blog = ({ posts }) => {
  return (
    <Container>
      <div className={styles.whole}>
        {
          posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <article key={node.fields.slug} className={styles.article}>
                <Link to={node.fields.slug}>
                  {node.frontmatter.featuredImage && (
                    <AspectRatio ratio={16/9}>
                      <GatsbyImage
                        className={styles.articleImage}
                        fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                      ></GatsbyImage>
                    </AspectRatio>
                  )}
                </Link>
                <h2 className={styles.articleTitle}>
                  <Link to={node.fields.slug}>
                    { title }
                  </Link>
                </h2>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
                <div className={styles.date}>{ node.frontmatter.date }</div>
              </article>
            )
          })
        }
      </div>
    </Container>
  )
}

export default Blog
